import { Component, OnInit } from '@angular/core';
import { StorageService } from '../core/services/storage.service';
import { first } from 'rxjs/operators';
import { CookieService } from 'ngx-cookie-service';
import { BotonesService } from '../core/services/botones.service';
import { Establecimiento } from '../core/models/establecimiento';
import { MatDialog } from '@angular/material';
import { PoliticaCookiesComponent } from '../politica-cookies/politica-cookies.component';
import { environment } from 'src/environments/environment';
import { DataCookie } from '../core/models/data-cookie';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {

  // cookieNombre = 'CookieServiciosMesa';
  strColorCuerpo:string=environment.strColorCuerpo;
  strColorTextoCuerpo:string=environment.strColorTextoCuerpo;

  MuestraEst=false;

  colorCuerpo:string;
  colorTextoCuerpo:string;
  //NO PUEDO COGER EL NOMBRE DE LA SESION PORQUE EN EL LOGIN NO HAY SESION INICIADA
  //nombreApp:string=this.storageService.getCurrentSession().strNombre;
  nombreApp:string;
  tituloApp:string;
  aceptCookies:boolean=true;
  // IsCookieExists:boolean=this.cookieService.check(this.cookieNombre);
  miEstablecimiento:Establecimiento=new Establecimiento();
  midataCookie:DataCookie=new DataCookie();
  cookieNombreNavegador:string=environment.cookieNombreNavegador;
  cookieNombre:string=environment.cookieNombre;
  idEstablecimiento:string="";

  constructor(private route: ActivatedRoute,private miservicio:BotonesService,private dialog: MatDialog,
    private cookieService: CookieService ) {
      this.route.queryParams.subscribe(params => {
        this.idEstablecimiento=params["id"];
      });
     }

  ngOnInit() {
    // this.colorCuerpo=sessionStorage.getItem(this.strColorCuerpo);
    // this.colorTextoCuerpo=sessionStorage.getItem(this.strColorTextoCuerpo);
    // this.getNombreApp();
    this.midataCookie=JSON.parse(this.cookieService.get(this.cookieNombre+this.idEstablecimiento));
    setTimeout(()=>{
      // this.colorCuerpo=this.midataCookie.strColorCuerpo;
      this.colorTextoCuerpo=this.midataCookie.strColorTextoCuerpo;
      // this.colorMenu=this.midataCookie.strColorMenu;
      // this.colorTextoMenu=this.midataCookie.strColorTextoMenu;
      // this.strMesa=this.midataCookie.nombreMesa;
      // this.strZona=this.midataCookie.nombreZona;
      // this.getNombreApp();
    }, 500)

    let IsCookieExists:boolean=this.cookieService.check(this.cookieNombreNavegador+this.idEstablecimiento);
    // console.log(IsCookieExists)
    if(IsCookieExists){
      this.aceptCookies=false;
    }else{
      this.aceptCookies=true;
    }
  }

  cancelar(){
    this.aceptCookies=false;
  }

  // getNombreApp(){
  //   this.miservicio.getEstablecimiento(this.midataCookie.coo).subscribe(datos => { 
  //     this.miEstablecimiento=datos[0];
  //     this.MuestraEst=true;
  //     // console.log(datos)

  //     if(this.miEstablecimiento.strTelefono1!=null && this.miEstablecimiento.strTelefono1!=""){
  //       this.miEstablecimiento.strTelefono1=" - " + this.miEstablecimiento.strTelefono1;
  //     }
  //     if(this.miEstablecimiento.strTelefono2!=null && this.miEstablecimiento.strTelefono2!=""){
  //       this.miEstablecimiento.strTelefono2=" - " + this.miEstablecimiento.strTelefono2;
  //     }
  //     if(this.miEstablecimiento.strDireccion!=null && this.miEstablecimiento.strDireccion!=""){
  //       this.miEstablecimiento.strDireccion=" - " + this.miEstablecimiento.strDireccion;
  //     }
  //     if(this.miEstablecimiento.strPoblacion!=null && this.miEstablecimiento.strPoblacion!=""){
  //       this.miEstablecimiento.strPoblacion=" - " + this.miEstablecimiento.strPoblacion;
  //     }
  //     if(this.miEstablecimiento.strCp!=null && this.miEstablecimiento.strCp!=""){
  //       this.miEstablecimiento.strCp=" - " + this.miEstablecimiento.strCp;
  //     }
  //     if(this.miEstablecimiento.strProvincia!=null && this.miEstablecimiento.strProvincia!=""){
  //       this.miEstablecimiento.strProvincia=" - " + this.miEstablecimiento.strProvincia;
  //     }

  //   })
  // }


  aceptaCookies(){
    this.cookieService.set( this.cookieNombreNavegador+this.idEstablecimiento, 'ServiciosMesa',365,'',window.location.hostname );
    this.aceptCookies=false;
  }

  cookies(){
    // window.open('//google.com');

    let dialogRef = this.dialog.open(PoliticaCookiesComponent, {
      width:'600px',
      data:this.midataCookie
    });
    dialogRef.afterClosed().subscribe(result =>{
      if(result){
        this.cookieService.set(this.cookieNombreNavegador+this.idEstablecimiento,'ServiciosMesa',365,'',window.location.hostname);
        this.aceptCookies=false;
      }
    })
  }

}
