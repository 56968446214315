import { Component, OnInit, HostListener } from '@angular/core';
import { ActivatedRoute, Router, NavigationExtras } from '@angular/router';
import { Boton } from '../core/models/boton';
import { BotonesService } from '../core/services/botones.service';
import { first } from 'rxjs/operators';
import { MatDialog } from '@angular/material';
import { EsperarServicioComponent } from '../esperar-servicio/esperar-servicio.component';
import { LoadingPedidoComponent } from '../loading-pedido/loading-pedido.component';
import { Establecimiento } from '../core/models/establecimiento';
import { environment } from 'src/environments/environment';
import { DataCookie } from '../core/models/data-cookie';
import { CookieService } from 'ngx-cookie-service';

@Component({
  selector: 'app-botones',
  templateUrl: './botones.component.html',
  styleUrls: ['./botones.component.scss']
})
export class BotonesComponent implements OnInit {

  listBotones:Boton[]=[];
  // listEstablecimiento:Establecimiento[]=[];

  colorCuerpo:string;
  colorMenu:string;
  colorTextoCuerpo:string;
  colorTextoMenu:string;

  cookieNombre:string=environment.cookieNombre;
  MesaId:string=environment.intIdMesa;

  // strColorCuerpo:string=environment.strColorCuerpo;
  // strColorTextoCuerpo:string=environment.strColorTextoCuerpo;
  muestraContenido=false;
  myLoader:boolean=false;
  strMesa:string;
  strZona:string;
  // strNombreZona:string=environment.strNombreZona;
  // strNombreMesa:string=environment.strNombreMesa;
  midataCookie:DataCookie=new DataCookie();
  idEstablecimiento:string="";
  miEstablecimiento:Establecimiento= new Establecimiento();

  constructor(private miservicio:BotonesService,private route: ActivatedRoute,private router: Router,public dialog: MatDialog,private cookieService: CookieService) {
    this.route.queryParams.subscribe(params => {
      this.idEstablecimiento=params["id"];
    });
   }

  ngOnInit() {  
    this.myLoader=true;
    // this.listarBotones(); 

    // this.colorCuerpo=sessionStorage.getItem(this.strColorCuerpo);
    // this.colorTextoCuerpo=sessionStorage.getItem(this.strColorTextoCuerpo);

    // this.strMesa=sessionStorage.getItem(this.strNombreMesa);
    // this.strZona=sessionStorage.getItem(this.strNombreZona);

    this.midataCookie=JSON.parse(this.cookieService.get(this.cookieNombre+this.idEstablecimiento));
    // console.log(this.midataCookie)
    setTimeout(()=>{
      this.colorCuerpo=this.midataCookie.strColorCuerpo;
      this.colorTextoCuerpo=this.midataCookie.strColorTextoCuerpo;
      this.strMesa=this.midataCookie.nombreMesa;
      this.strZona=this.midataCookie.nombreZona;
      this.listarBotones();
    }, 500)
  }

  @HostListener('window:beforeunload', ['$event']) beforeUnloadHander(event) {
    sessionStorage.clear();
  }

  listarBotones(){
    // if(this.midataCookie.coo!=undefined){
      this.miservicio.getBotones(this.midataCookie.coo, this.midataCookie.pro).subscribe(datos=>{
        // this.listBotones=datos;
        // this.myLoader=false;
        // this.muestraContenido=true;
        // console.log(datos)
        if(datos.miRespuesta.booOk){
          // this.muestraContenido=true;
          this.listBotones=datos.miListProducto;

          this.getDatosEst();
        }
      })
    // }else{
    //   console.log(this.midataCookie)
    // }
  }

  getDatosEst(){
    this.miservicio.getEstablecimiento(this.midataCookie.coo).subscribe(datos=>{
      // console.log(datos)
      this.myLoader=false;
      this.muestraContenido=true;
      if(datos.miRespuesta.booOk){
        this.miEstablecimiento=datos.miListEstablecimiento[0];
        // console.log(this.miEstablecimiento)

        if(this.miEstablecimiento.strTelefono1!=null && this.miEstablecimiento.strTelefono1!=""){
          this.miEstablecimiento.strTelefono1=" - " + this.miEstablecimiento.strTelefono1;
        }
        if(this.miEstablecimiento.strTelefono2!=null && this.miEstablecimiento.strTelefono2!=""){
          this.miEstablecimiento.strTelefono2=" - " + this.miEstablecimiento.strTelefono2;
        }
        if(this.miEstablecimiento.strDireccion!=null && this.miEstablecimiento.strDireccion!=""){
          this.miEstablecimiento.strDireccion=" - " + this.miEstablecimiento.strDireccion;
        }
        if(this.miEstablecimiento.strPoblacion!=null && this.miEstablecimiento.strPoblacion!=""){
          this.miEstablecimiento.strPoblacion=" - " + this.miEstablecimiento.strPoblacion;
        }
        if(this.miEstablecimiento.strCp!=null && this.miEstablecimiento.strCp!=""){
          this.miEstablecimiento.strCp=" - " + this.miEstablecimiento.strCp;
        }
        if(this.miEstablecimiento.strProvincia!=null && this.miEstablecimiento.strProvincia!=""){
          this.miEstablecimiento.strProvincia=" - " + this.miEstablecimiento.strProvincia;
        }

        // setTimeout(() => {
        //   // this.router.navigate(['/ServciosMesa']);
        // }, 1000);  
        
      }
    })
  }

  // datosEstablecimiento(){
  //   this.miservicio.getEstablecimiento(this.midataCookie.coo).subscribe(datos=>{    
  //     //console.log(datos); 
  //     this.listEstablecimiento=datos;
  //     //console.log(this.listEstablecimiento);
  //     this.colorCuerpo="#"+this.listEstablecimiento[0].strColorCuerpo;
  //     this.colorTextoCuerpo="#"+this.listEstablecimiento[0].strColorTextoCuerpo;
  //     this.colorMenu="#"+this.listEstablecimiento[0].strColorMenu;
  //     this.colorTextoMenu="#"+this.listEstablecimiento[0].strColorTextoMenu;
  //   })
  // }

  pulsar(boton){
    // console.log(boton)
    // let dialogRef = this.dialog.open(LoadingPedidoComponent, {
    //   width: '600px',
    //   height:'600px',
    //   // data: datos.strValor
    // });

    this.miservicio.llamaServicio(this.midataCookie.coo,boton,this.midataCookie.mes).subscribe(datos=>{
      // console.log(datos)
      if(datos.booOk){
        let dialogRef = this.dialog.open(LoadingPedidoComponent, {
          width: '600px',
          height:'600px',
          // data: datos.strValor
        });
      }else{
        let dialogRef = this.dialog.open(EsperarServicioComponent, {
          width: '600px',
          height:'600px',
          data: datos.strValor
        });
      }
    })
  }

}
