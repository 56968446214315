import { Component, OnInit, Inject } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { MAT_DIALOG_DATA, MatDialogRef, MatDialog } from '@angular/material';

@Component({
  selector: 'app-loading-pedido',
  templateUrl: './loading-pedido.component.html',
  styleUrls: ['./loading-pedido.component.scss']
})
export class LoadingPedidoComponent implements OnInit {

  constructor(public thisDialogRef: MatDialogRef<MatDialog>, @Inject(MAT_DIALOG_DATA) public data: any) {
    
  }

  ngOnInit() {

    setTimeout(() => {
      //cargo los datos de la cookie en la sesion y redirijo a la carta online
      //this.correctLogin(this.datos);
      // this.router.navigate(['/ServciosMesa']);
      this.thisDialogRef.close();
    }, 3000);
  }

}
