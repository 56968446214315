// This file can be replaced during build by using the `fileReplacements` array.
// `ng build ---prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  //baseUrl : 'https://webservice.infitec.es/resttpvwa',
  //baseUrl : 'http://192.168.1.135/resttpvwa',
  // baseUrl : 'http://localhost:35113',
  baseUrl : 'https://resttpvwa.infitec.es',

  //Variables cookies
  cookieNombre:"CookieServiciosMesa",
  cookieNombreNavegador:"CookieServiciosMesaNavegador",
  intIdMesa:"intMesaIdServiciosMesa",
  strColorCuerpo:"strColorCuerpoServiciosMesa",
  strColorTextoCuerpo:"strColorTextoCuerpoServiciosMesa",
  strNombreMesa:"strMesaServiciosMesa",
  strNombreZona:"strZonaServiciosMesa",
};

/*
 * In development mode, to ignore zone related error stack frames such as
 * `zone.run`, `zoneDelegate.invokeTask` for easier debugging, you can
 * import the following file, but please comment it out in production mode
 * because it will have performance impact when throw error
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
