import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { StorageService } from './storage.service';
import { environment } from 'src/environments/environment';
import { Observable } from 'rxjs';
import { Boton } from '../models/boton';
import { Establecimiento } from '../models/establecimiento';
import { MesaResult } from '../models/mesa-result';
import { EstablecimientoResult } from '../models/establecimiento-result';
import { BotonResult } from '../models/boton-result';
import { Respuesta } from '../models/respuesta';

@Injectable({
  providedIn: 'root'
})
export class BotonesService {

  constructor(private http: HttpClient, private storageService: StorageService ) {}

  public url: string = environment.baseUrl+'/api/';
  private token=this.storageService.getCurrentToken();

  httpOptions = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json'})
  };

  getBotones(strCookie,intIdServicio):Observable<BotonResult>{ 
    let dato='strCookie='+strCookie+'&intIdServicio='+intIdServicio;
    let urlCompleta=this.url+'Servicio/detalle?'+dato;
    return this.http.get<BotonResult>(urlCompleta,  this.httpOptions);
  }

  getEstablecimiento(cookie):Observable<EstablecimientoResult>{ 
    let dato='strCookie='+cookie;
    let urlCompleta=this.url+'establecimiento/general/detalle?'+dato;
 
    return this.http.get<EstablecimientoResult>(urlCompleta,  this.httpOptions);
  }

  getMesas(cookie,mesa):Observable<MesaResult>{
    let dato='strCookie='+cookie+'&intMesaId='+mesa;
    let urlCompleta=this.url+'Mesa/detalle?'+dato;
 
    return this.http.get<MesaResult>(urlCompleta, this.httpOptions);
  }

  llamaServicio(cookie,boton:Boton,mesa):Observable<Respuesta>  {
     
      let urlCompleta=this.url+'Servicio?'+"strCookie="+cookie ;
  
      let dato:any = {};
      dato.intProductoId = boton.intId;
      dato.strProductoNombre=boton.strNombre;
      dato.strDatos=mesa + ", " + boton.strDescripcion;
      dato.strDescargado="";
      dato.intMesaId=mesa;
  
      var jsonString = JSON.stringify(dato);
  
      return this.http.post<Respuesta>(urlCompleta,jsonString,this.httpOptions);
    
  }

  getPoliticaCookies(cookie):Observable<any>{ 
    let dato='strMiCookie='+cookie;
    let urlCompleta=this.url+'establecimiento?'+dato;
 
    return this.http.get<any>(urlCompleta,  this.httpOptions);
  }
}
