import { Component, OnInit, Inject } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material';

@Component({
  selector: 'app-esperar-servicio',
  templateUrl: './esperar-servicio.component.html',
  styleUrls: ['./esperar-servicio.component.scss']
})
export class EsperarServicioComponent implements OnInit {

  strValor:any;

  constructor(public thisDialogRef: MatDialogRef<MatDialog>, @Inject(MAT_DIALOG_DATA) public data: any) {
    // this.route.queryParams.subscribe(params => {
    //   this.strValor=params["time"];
    // });
   }
  
  ngOnInit() {
    this.strValor=this.data;
      // console.log(this.data)

    setTimeout(() => {
      
      //cargo los datos de la cookie en la sesion y redirijo a la carta online
      //this.correctLogin(this.datos);
      // this.router.navigate(['/ServciosMesa']);
      this.thisDialogRef.close();
    }, 3000);
  }

}
