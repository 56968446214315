import {User} from "./user.model";
import { Token } from "./token";

export class Session {
  public strToken: string;
  public strMensaje:string;
  public expires:number;
  public token:Token;
  public user: User;

  //Datos generales de la aplicacion

  public strNombre?:string;
  public strNombreTitulo?:string;

  //public token:Token;
  /*public bolEstado:boolean;
    public strUsuario:string;
    public strRol:string;
    public strMensaje:string;*/
}
